import React, { useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import moment from "moment";
import tw from "tailwind-styled-components";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  PrimaryText,
  TostMessage,
  SuccessModal,
  BookingAlertMOdal,
} from "@components";
import { remove } from "@utils";
import {
  isModal,
  payLoader,
  getBookingData,
  createPaymentIntent,
  paymentUpdateBooking,
  getSelectedPlayerData,
} from "@store";

function PaymentDetails(props) {
  const { onPayClick, inputComplete, teeTime_Data, compation_Data, type } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation("common");
  const playersPriceList = location.state;
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const facilityData = useSelector((state) => state.courses?.clubCoursData);
  const clubData = useSelector((state) => state.clubs.clubsData);
  const clubPhone = clubData?.data ? clubData?.data[0]?.attributes?.phone : "";

  const facilitiesData = clubData?.data?.[0]?.attributes?.facility?.data;
  const bookingSlot = compation_Data?.tournamentData?.slot;
  const bookingTeeTime = compation_Data?.tournamentData?.teeTime;

  let prices, totalPrice;
  if (type === "individual") {
    prices = addToCartData?.length
      ? addToCartData?.map((a) => a?.price?.publicRate?.amount)
      : "";
    totalPrice = addToCartData?.length
      ? prices.reduce((acc, curr) => acc + curr)
      : "";
  } else {
    prices = playersPriceList?.length
      ? playersPriceList?.map((a) => a?.activePrice)
      : "";
    totalPrice = playersPriceList?.length
      ? prices?.reduce((acc, curr) => acc + curr)
      : "";
  }

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      payError: false,
      payDetail: null,
      successModal: false,
      alertModal: false,
      alertMessage: false,
      alertMessageTxt: null,
      bookings_List: [],
    }
  );

  useEffect(() => {
    if (inputComplete) {
      if (state.payDetail === null || !state.payDetail?.complete) {
        setState({ payError: true });
      } else {
        setState({ payError: false, alertModal: true });
        dispatch(payLoader(false));
        handleSubmit();
      }
    }
  }, [inputComplete]);

  useEffect(() => {
    if (state.successModal) {
      dispatch(isModal(true));
    } else {
      dispatch(isModal(false));
    }
  }, [state.successModal]);

  useEffect(() => {
    const formatTeeTime = (time) => moment(time).format("YYYY-MM-DDTHH:mm");
    const courseName =
      compation_Data?.tournamentData?.data?.courseId?.courseName;
    const createPlayerDetail = (index) => ({
      teeId: bookingTeeTime?.data?.id,
      teeTime: formatTeeTime(bookingTeeTime?.data?.time),
      holes: bookingTeeTime?.data?.holes,
      amount: playersPriceList?.[index]?.activePrice,
      course: courseName,
      players: 1,
    });
    const playerArr = Array.from({ length: bookingSlot?.id || 0 }, (_, index) =>
      createPlayerDetail(index)
    );
    setState({ bookings_List: playerArr });
  }, [playersPriceList, bookingSlot]);

  const closeSuccessModal = () => {
    setState({
      successModal: false,
    });
    window.scrollTo(0, 0);
    if (type === "individual") {
      localStorage.removeItem("AddToCartsData");
      dispatch(payLoader(false));
      dispatch(getSelectedPlayerData({ save: false }));
      navigate("/");
    } else {
      navigate("/tournament");
      remove("localUserData");
    }
  };

  const change = (e) => {
    setState({ payError: false, payDetail: e });
    onPayClick();
  };

  const handleSubmit = async () => {
    onPayClick();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement, CardNumberElement);
    const totalAmount =
      type === "individual"
        ? teeTime_Data.multipleBookingPayment.reduce((acc, booking) => {
            return acc + booking.amount;
          }, 0)
        : compation_Data.bookingListPayload.reduce((acc, booking) => {
            return acc + booking.netRate;
          }, 0);

    const paymentApiData =
      type === "individual"
        ? {
            name: teeTime_Data?.firstName + " " + teeTime_Data?.lastName,
            email: teeTime_Data?.email,
            contactPhone: teeTime_Data?.telePhone,
            currency: "EUR",
            bookingPayment: teeTime_Data?.multipleBookingPayment,
            facilityId: facilityData?.id,
            street: teeTime_Data?.street,
            companyName: teeTime_Data?.companyName,
            city: teeTime_Data?.town,
            zipCode: teeTime_Data?.zipCode,
            amount: totalAmount,
            country: "Ireland",
            description: teeTime_Data?.specialRequests
              ? teeTime_Data?.specialRequests
              : "Booking Tee-Time",
          }
        : {
            name: compation_Data?.firstName + " " + compation_Data?.lastName,
            email: compation_Data?.email,
            contactPhone: compation_Data?.telePhone,
            bookingPayment: state?.bookings_List,
            facilityId: facilityData?.id,
            country: compation_Data?.country?.label,
            handicap: compation_Data?.handicap,
            cdhNumber: compation_Data?.cdhNumber,
            amount: totalAmount,
            currency: "EUR",
            description: compation_Data?.specialRequests
              ? compation_Data?.specialRequests
              : "Booking open-competition Tee-Time",
          };
    createPayment(paymentApiData, cardElement);
  };

  const createPayment = async (apiData, cardElement) => {
    await dispatch(createPaymentIntent(apiData)).then(async (res) => {
      let paymentRes = res?.payload?.data?.data?.paymentsDetail;
      if (res?.payload?.data?.data?.payment?.status === "intent-generated") {
        let securePayment = await stripe.confirmCardPayment(
          paymentRes?.clientSecret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (securePayment?.paymentIntent?.status === "succeeded") {
          bookingTees(paymentRes);
        } else {
          setState({
            alertMessage: true,
            alertModal: false,
            alertMessageTxt:
              securePayment?.error?.message ||
              "A generic error occurred on the confirm Card Payment !",
          });
        }
      } else if (
        res?.payload?.response?.data?.data?.payment?.message &&
        res?.payload?.response?.data?.data?.payment?.status === "failed"
      ) {
        setState({
          alertMessage: true,
          alertModal: false,
          alertMessageTxt: `${res?.payload?.response?.data?.data?.payment?.message} Please try again with another card`,
        });
      } else {
        res?.payload?.response?.data?.message
          ? setState({
              alertMessage: true,
              alertModal: false,
              alertMessageTxt: res?.payload?.response?.data?.message,
            })
          : res?.payload?.response?.data?.error?.message
          ? setState({
              alertMessage: true,
              alertModal: false,
              alertMessageTxt: res?.payload?.response?.data?.error?.message,
            })
          : res?.payload?.response?.data?.errors[0]?.message
          ? setState({
              alertMessage: true,
              alertModal: false,
              alertMessageTxt: res?.payload?.response?.data?.errors[0]?.message,
            })
          : setState({
              alertMessage: true,
              alertModal: false,
              alertMessageTxt:
                "A generic error occurred on the Payment Intent !",
            });
      }
    });
  };

  const bookingTees = async (paymentRes) => {
    const bookingApiData =
      type === "individual"
        ? {
            facilityId: facilityData?.id,
            contactPhone: teeTime_Data?.telePhone,
            bookings: teeTime_Data?.bookingList,
            contactEmail: teeTime_Data?.email,
            clubPhone: clubPhone,
            amountPaid: totalPrice,
            contactFirstName: teeTime_Data?.firstName,
            contactLastName: teeTime_Data?.lastName,
            paymentIntent: paymentRes?.paymentIntentId,
          }
        : {
            bookings: compation_Data?.bookingListPayload,
            facilityId: facilitiesData?.id,
            contactPhone: compation_Data?.telePhone,
            contactEmail: compation_Data?.email,
            amountPaid: totalPrice,
            clubPhone: clubData?.data
              ? clubData?.data?.[0]?.attributes?.phone
              : "",
            contactFirstName: compation_Data?.firstName,
            contactLastName: compation_Data?.lastName,
            paymentIntent: paymentRes?.paymentIntentId,
          };
    await dispatch(getBookingData(bookingApiData)).then((res) => {
      if (
        res?.payload?.status === 200 &&
        res?.payload?.data &&
        res?.payload?.data?.data?.groupId
      ) {
        let bookingRes = res?.payload?.data?.data;
        const newFormattedBookingData = bookingRes?.bookingIds?.map(
          ({ teetime, courseId, ...rest }) => ({
            ...rest,
            teeTime: moment(teetime).format("YYYY-MM-DDTHH:mm"),
          })
        );
        let data = {
          paymentId: paymentRes?.paymentId,
          paymentIntentId: paymentRes?.paymentIntentId,
          paymentLogs: newFormattedBookingData,
          bulkBookingId: bookingRes?.groupId,
        };
        dispatch(paymentUpdateBooking(data));
        setState({ successModal: true, alertModal: false });
      } else {
        let data = {
          paymentId: paymentRes?.paymentId,
          paymentIntentId: paymentRes?.paymentIntentId,
          status: "failed",
          paymentCancelReason: "Payment got canceled due to booking failed",
        };
        dispatch(paymentUpdateBooking(data));
        if (res?.payload?.data?.raw?.message) {
          setState({
            alertMessage: true,
            alertModal: false,
            alertMessageTxt: res?.payload?.data?.raw?.message,
          });
        } else if (res?.payload?.response?.data?.error?.message) {
          setState({
            alertMessage: true,
            alertModal: false,
            alertMessageTxt: res?.payload?.response?.data?.error?.message,
          });
        } else if (res?.payload?.response?.data?.message) {
          setState({
            alertMessage: true,
            alertModal: false,
            alertMessageTxt: res?.payload?.response?.data?.message,
          });
        } else {
          setState({
            alertMessage: true,
            alertModal: false,
            alertMessageTxt: "A generic error occurred on the Booking time !",
          });
        }
      }
    });
  };

  return (
    <>
      <div className="mt-8 mb-4">
        <PrimaryText
          className={type === "individual" ? ` ${title} opacity-80` : title}
        >
          {t("tournamentCheckout.paymentMethod")}
        </PrimaryText>
      </div>
      <Component>
        <div className="w-full h-auto">
          <CardElement
            onChange={(e) => change(e)}
            className={`${payment_Input}`}
          />

          <Collapse in={state.payError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setState({ payError: false });
                    onPayClick();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              missing card details for payment — <strong>check it out!</strong>
            </Alert>
          </Collapse>
          <BookingAlertMOdal
            openModal={state.alertModal}
            // closeModal={close_AlertModal}
          />
          <SuccessModal
            openModal={state.successModal}
            closeModal={closeSuccessModal}
          />
          {state.alertMessageTxt && (
            <TostMessage
              open={state.alertMessage}
              onClose={() =>
                setState({ alertMessage: false, alertMessageTxt: null })
              }
              title={state.alertMessageTxt}
              type={"info"}
            />
          )}
        </div>
      </Component>
    </>
  );
}

const Component = tw.div`
w-full
h-auto 
flex
flex-col
items-center
justify-center
`;

const title = `
text-textColor 
text-xl 
font-medium 
`;

const payment_Input = `
w-full 
h-auto 
py-[15px]
px-4 
bg-white255
text-black21 
rounded-lg
focus:outline-none 
border-[#ffffff]
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;

export default PaymentDetails;
